import FroalaEditor from 'froala-editor';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';

document.addEventListener('turbo:load', () => {
  new FroalaEditor('.froala', froalaConfig(-1, false, null));
  new FroalaEditor('.froala-short', froalaConfig(400, true, 200));
  $('#the_modal').on('shown.bs.modal', function () {
    new FroalaEditor('.froala', froalaConfig(-1, false, null));
    new FroalaEditor('.froala-short', froalaConfig(400, true, 200));
  });
});

function froalaConfig(charCounterMax, pastePlain, heightMax) {
  var isTyping = false;
  var isNotTyping;
  var interval;

  function sendIsTypingToUser(){
    if(!isTyping){
      isTyping = true
      keepServer()
    }
  }
  function sendIsNotTyping(){
    isTyping = false
    killServer()
  }
  function keepServer(){
    if (!interval) {
      interval = setInterval(function(){
        $.ajax({
          url: "/server_messages/ping",
          type: 'GET'
        });
      }, 1000 * 60 * 2);
    }
  }

  function killServer(){
    clearInterval(interval);
    interval = null;
  }
  var pathname = window.location.pathname
  var members_list = []
  $.ajax({
    url: pathname,
    dataType: "json",
    success:  function(data){ 
      members_list = data;
    }
  });

  var tribute = new Tribute({
    values: function (text, cb) {
      remoteSearch(text, users => cb(users));
      function remoteSearch(text, cb) {
        cb(members_list)
      }
    },
    selectClass: 'tribute-select-class',
    menuItemTemplate: function (item) {
      return '<span class = "col-12" style = "font-weight: normal;"> <img class = "rounded-circle align-self-center mr-2" src= '+ item.original.avatar_url + '  width = "30", height = "30">'+item.original.user_name+' </span>';
    },
    selectTemplate: function(item) {
      return '<span contenteditable="true" class = "select-template-tribute-item"><a contenteditable="false" href="../../manage/users/'+item.original.user_id+'?from_chatterbox=true" class = "text-center text-primary">@' + item.original.user_name +'</a></span>'
    }
  })

  return {
    key:
      'xGE6oB4E4J4B11A8D7fLUQZf1ASFb1EFRNh1Hb1BCCQDUHnA8B6E5B4B1C3E4A1B8A5==',
    imageUploadURL: '/editor/assets',
    imageUploadMethod: 'POST',
    imageUploadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
    },
    fontFamilySelection: true,
    fontFamilyDefaultSelection: 'Arial',
    charCounterMax,
    pastePlain,
    heightMax,
    attribution: false,
    linkAlwaysBlank: true,
    imageManagerLoadURL: '/editor/assets',
    videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
    fileUploadURL: '/editor/assets',
    fileUploadMethod: 'POST',
    fileUploadParams: {
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
    },
    events: {
      'keypress': function (keypressEvent) {
        sendIsTypingToUser()
        if (isNotTyping != undefined) clearTimeout(isNotTyping);
        isNotTyping = setTimeout(sendIsNotTyping, 1000 * 60 * 10);
      },
      initialized: function() {
        var editor = this;
        tribute.attach(editor.el)

        editor.events.on('keydown', function(e) {
          if (e.which == FroalaEditor.KEYCODE.ENTER && tribute.isActive) {
            return false;
          }
        }, true);
      }
    }
  };
}