import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bulk-progress"
export default class extends Controller {
  static targets = ["requirements"];

  connect() {
    $('#accordion').on('hidden.bs.collapse', this.onCollapseHidden.bind(this));
    $('#accordion').on('shown.bs.collapse', this.onCollapseShown.bind(this));
  }

  requirements(event) {
    $('#record-progress-bulk').smartWizard("loader", "show");
    event.preventDefault()
    const button = event.currentTarget
    const award_type = button.dataset.achievement;
    const [achievementType, achievementId] = button.dataset.achievement.split('-');
    const url = `/achieve/get_achievement?award_type=${achievementType}&award_id=${achievementId}`;
    this.load(url, award_type)
  }

  load(url, award_type) {
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.requirementsTarget.innerHTML =
          '<div class="form-check"><input name="bulk_award[all_requirements]" type="hidden" value="0" autocomplete="off"><input class="form-check-input" type="checkbox" value="1" name="bulk_award[all_requirements]" id="bulk_award_all_requirements"><label class="form-check-label" for="bulk_award_all_requirements">All requirements completed</label></div>'
          +
          '<br/>'
          +
          `<input type="hidden" value="${award_type}" name="bulk_award[award_type]" id="bulk_award_award_type">`
          +
          html;
        window.scrollTo({ top: 0, behavior: 'smooth' });
        $('#record-progress-bulk').smartWizard("fixHeight");
        $('#record-progress-bulk').smartWizard("loader", "hide");
      })
      .catch(error => {
        console.error('Error loading data:', error);
        $('#record-progress-bulk').smartWizard("loader", "hide");
      });
  }

  onCollapseHidden(event) {
    $('#record-progress-bulk').smartWizard("fixHeight");
  }

  onCollapseShown(event) {
    $('#record-progress-bulk').smartWizard("fixHeight");
  }

}
