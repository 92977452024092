import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="countdown"
export default class extends Controller {
  static values = { eventTrackerId: String, rsvpInCart: String, createdAt: String }

  connect() {
    if (this.rsvpInCartValue) {
      this.startCountdown()
    }
  }

  startCountdown() {
    const span = this.element.querySelector(`#countdown_timer_${this.eventTrackerIdValue}`)
    const createdAtTime = new Date(this.createdAtValue)
    const expirationTime = createdAtTime.setHours(createdAtTime.getHours() + 2)

    this.interval = setInterval(() => {
      const timeRemaining = expirationTime - new Date().getTime()
      if (timeRemaining >= 0) {
        const remainingDate = new Date(timeRemaining)
        const hours = remainingDate.getUTCHours()
        const minutes = remainingDate.getUTCMinutes()
        const seconds = remainingDate.getUTCSeconds()
        const formattedTime = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
        span.textContent = formattedTime
      } else {
        span.textContent = "Expired"
        this.element.querySelector(`#checkout_button_${this.idValue}`).classList.add('disabled')
        clearInterval(this.interval)
      }
    }, 1000)
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

}