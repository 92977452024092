import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

// Connects to data-controller="appearance"
export default class extends Controller {
  static targets = [ "user" ]

  connect() {
    this.appearanceChannel = consumer.subscriptions.create("AppearanceChannel", {
      received: this.updateUserStatus.bind(this)
    })
  }

  disconnect() {
    this.appearanceChannel.unsubscribe()
  }

  updateUserStatus(data) {
    const userElements = this.userTargets.filter(element => element.dataset.userAccountId == data.user_account_id);
    userElements.forEach(userElement => {
      userElement.classList.toggle("online", data.online);
      userElement.classList.toggle("offline", !data.online);
    });
  }
}
