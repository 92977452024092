import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["messages", "input"]

  connect() {
    const body = $("body");
    this.userId = body.data().userId;
    if (body.data().useChat) {
      this.channel = consumer.subscriptions.create("ChatChannel", {
        connected: this.connected.bind(this),
        disconnected: this.disconnected.bind(this),
        received: this.received.bind(this)
      })
    }
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  connected() {
    // console.log("Connected to the chat channel.")
  }

  disconnected() {
    // console.log("Disconnected from the chat channel.")
  }

  received(data) {
    switch (data.action) {
      case 'create':
        this.createMessage(data);
        break;
      case 'update':
        this.updateMessage(data);
        break;
      case 'delete':
        this.deleteMessage(data);
        break;
      default:
        break;
    }
    this.scrollMessages();
    // this.messagesTarget.insertAdjacentHTML("beforeend", `<div>${data.message}</div>`)
  }

  createMessage(data) {
    if (data.user_id === this.userId) {
      $(".chatterbox#chatterbox_" + data.chatterbox_id + " .messages").append(
        this.myMessage(data),
      );
    } else {
      $(".chatterbox#chatterbox_" + data.chatterbox_id + " .messages").append(
        this.yourMessage(data),
      );
    }
  }

  myMessage(data) {
    var dateCreated = new Date(data.created_at);
    return (
      "<div class='media w-75 ml-auto mb-3 message_" + data.id + "'>\
        <div class='media-body mr-2 position-relative'>\
          <div class='triangle-left'></div>\
          <div class='rounded py-2 px-3 mb-2 sender-message'>\
            <div class='text-small mb-0 text-white message_content'>" + data.content + "</div>\
          </div>\
          <p class='small text-muted'>" + data.created_by + " - " + dateCreated.toDateString() + "\
            <a data-method='delete' class='text-decoration-none pull-right text-secondary' data-confirm='Are you sure you want to delete this message?' data-disable-with='Deleting' data-remote='true' href='/communicate/chatterboxes/" + data.chatterbox_id + "/chat_messages/" + data.id + "'>\
              <i class='fa fa-trash fa-fw'></i>\
            </a>\
            <a data-remote='true' class='text-decoration-none pull-right text-secondary' href='/communicate/chatterboxes/" + data.chatterbox_id + "/chat_messages/" + data.id + "/edit'>\
              <i class='fa fa-pencil-square-o fa-fw'></i>\
            </a>\
          </p>\
        </div>\
        <div class='user-avatar-wrapper online' data-appearance-target='user' data-user-account-id='" + data.user_account_id + "'>\
          <img src='" + data.avatar_url + "' class='rounded-circle' width='50' height='50' alt='user' />\
          <div class='user-status-dot'></div>\
        </div>\
      </div>"
    );
  }

  yourMessage(data) {
    var dateCreated = new Date(data.created_at);
    return (
      "<div class='media w-75 mb-3 message_" + data.id + "'>\
        <div class='user-avatar-wrapper online' data-appearance-target='user' data-user-account-id='" + data.user_account_id + "'>\
          <img src='" + data.avatar_url + "' class='rounded-circle' width='50' height='50' alt='user' />\
          <div class='user-status-dot'></div>\
        </div>\
        <div class='media-body ml-2 position-relative'>\
          <div class='triangle-right'></div>\
          <div class='rounded py-2 px-3 mb-2 receiver-message'>\
            <div class='text-small mb-0 message_content'>" + data.content + "</div>\
          </div>\
          <p class='small text-muted'>" + data.created_by + " - " + dateCreated.toDateString() + "</p>\
        </div>\
      </div>"
    );
  }

  updateMessage(data) {
    $(
      ".chatterbox#chatterbox_" +
      data.chatterbox_id +
      " .messages .message_" +
      data.id +
      " .message_content",
    )
      .find("p")
      .empty()
      .html(data.content);
  }

  deleteMessage(data) {
    $(
      ".chatterbox#chatterbox_" +
      data.chatterbox_id +
      " .messages .message_" +
      data.id,
    ).remove();
  }

  scrollMessages() {
    const messagesDiv = $("#communicate_chatterboxes_page .chat-messages");
    if (messagesDiv.length) {
      messagesDiv[0].scrollTop = messagesDiv[0].scrollHeight;
    }
  }

}
