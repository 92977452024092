const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

document.addEventListener("turbo:load", () => {
	const embedConsole = async () => {
		const embedUrl = $("#quicksight-console-container").data("embedurl");

		const { createEmbeddingContext } = QuickSightEmbedding;

		const embeddingContext = await createEmbeddingContext({
			onChange: (changeEvent, metadata) => {
				console.log("Context received a change", changeEvent, metadata);
			},
		});

		const frameOptions = {
			url: embedUrl,
			container: "#quicksight-console-container",
			height: "700px",
			// width: "1000px",
			onChange: (changeEvent, metadata) => {
				switch (changeEvent.eventName) {
					case "FRAME_MOUNTED": {
						console.log("Do something when the experience frame is mounted.");
						break;
					}
					case "FRAME_LOADED": {
						console.log("Do something when the experience frame is loaded.");
						break;
					}
				}
			},
		};

		const contentOptions = {
			onMessage: async (messageEvent, experienceMetadata) => {
				switch (messageEvent.eventName) {
					case "ERROR_OCCURRED": {
						console.log(
							"Do something when the embedded experience fails loading.",
						);
						break;
					}
				}
			},
		};
		const embeddedConsoleExperience = await embeddingContext.embedConsole(
			frameOptions,
			contentOptions,
		);
	};

	const embedDashboard = async () => {
		const embedUrl = $("#quicksight-experience-container").data("embedurl");

		const { createEmbeddingContext } = QuickSightEmbedding;

		const embeddingContext = await createEmbeddingContext({
			onChange: (changeEvent, metadata) => {
				console.log("Context received a change", changeEvent, metadata);
			},
		});

		const frameOptions = {
			url: embedUrl,
			container: "#quicksight-experience-container",
			resizeHeightOnSizeChangedEvent: true,
			onChange: (changeEvent, metadata) => {
				switch (changeEvent.eventName) {
					case "FRAME_MOUNTED": {
						console.log("Do something when the experience frame is mounted.");
						break;
					}
					case "FRAME_LOADED": {
						console.log("Do something when the experience frame is loaded.");
						break;
					}
				}
			},
		};

		const contentOptions = {
			parameters: [
				{
					Name: "unitTypeId",
					Values: [50],
				},
			],
			locale: "en-US",
			sheetOptions: {
				initialSheetId: "1",
				singleSheet: false,
				emitSizeChangedEventOnSheetChange: false,
			},
			toolbarOptions: {
				export: true,
				undoRedo: false,
				reset: false,
			},
			attributionOptions: {
				overlayContent: false,
			},
			onMessage: async (messageEvent, experienceMetadata) => {
				switch (messageEvent.eventName) {
					case "CONTENT_LOADED": {
						console.log(
							"All visuals are loaded. The title of the document:",
							messageEvent.message.title,
						);
						break;
					}
					case "ERROR_OCCURRED": {
						console.log(
							"Error occurred while rendering the experience. Error code:",
							messageEvent.message.errorCode,
						);
						break;
					}
					case "PARAMETERS_CHANGED": {
						console.log(
							"Parameters changed. Changed parameters:",
							messageEvent.message.changedParameters,
						);
						break;
					}
					case "SELECTED_SHEET_CHANGED": {
						console.log(
							"Selected sheet changed. Selected sheet:",
							messageEvent.message.selectedSheet,
						);
						break;
					}
					case "SIZE_CHANGED": {
						console.log("Size changed. New dimensions:", messageEvent.message);
						break;
					}
					case "MODAL_OPENED": {
						window.scrollTo({
							top: 0, // iframe top position
						});
						break;
					}
				}
			},
		};
		const embeddedDashboardExperience = await embeddingContext.embedDashboard(
			frameOptions,
			contentOptions,
		);
	};
	if ($("#quicksight-console-container").length) {
		embedConsole();
	}
	if ($("#quicksight-experience-container").length) {
		embedDashboard();
	}
});
