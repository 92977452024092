import 'mmenu-js/dist/wrappers/turbolinks/mmenu.turbolinks';
import 'mmenu-js/dist/wrappers/bootstrap/mmenu.bootstrap';
import 'mmenu-js';

document.addEventListener('turbo:load', () => {
  const theme = document.querySelector('body[data-is-dark]')
    ? 'theme-dark'
    : 'theme-white';
  const menu = new Mmenu(
    '#mobileMenu',
    {
      wrappers: ['bootstrap', 'turbolinks'],
      offCanvas: { moveBackground: false },
      onClick: { close: true },
      navbar: { title: 'Menu' },
      extensions: [theme],
      // navbars: [
      //   {
      //     position: 'top',
      //     content: ['searchfield'],
      //   },
      // ],
      searchfield: {
        cancel: true,
        placeholder: 'Power Search',
        noResults: false,
        search: false,
        panel: {
          add: true,
          id: 'gazoogle-mobile',
        },
      },
    },
    {
      offCanvas: {
        page: {
          selector: '#page-content-wrapper',
        },
      },
      searchfield: {
        form: {
          action: '/gazoogle',
          'data-remote': 'true',
          method: 'get',
          id: 'new_gazoogle',
        },
        input: {
          name: 'gazoogle[query]',
          class: 'gazoogle search-query',
          id: 'gazoogle_query',
        },
      },
    }
  );

  const api = menu.API;
  const mobileMenuToggler = document.querySelector('#open-menu');

  if (mobileMenuToggler) {
    mobileMenuToggler.addEventListener('click', evnt => {
      evnt.preventDefault();
      if (document.querySelector('.mm-menu_opened')) {
        api.close();
      } else {
        api.open();
      }
    });
  }
});
